// Element
import ElementUI from "element-ui";
import "../element/variables.scss";
import "@/libs/util.request";
import "@/libs/util.permission";

// 组件
import "@/components";

export default {
  async install(Vue, options) {
    // 设置为 false 以阻止 vue 在启动时生成生产提示
    Vue.config.productionTip = false;
    // Element
    Vue.use(ElementUI);
  }
};
