import Vue from "vue";
import Router from "vue-router";
// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require("@/libs/util.import." + process.env.NODE_ENV);
Vue.use(Router);
export const constantRoutes = [
  {
    path: "/",
    name: "root",
    redirect: "/chartData"
  },
  {
    path: "/chartData",
    name: "chartData",
    component: _import("chartData/index")
  },
  {
    path: "*",
    name: "404",
    component: _import("error/404")
  }
];

export default new Router({
  routes: constantRoutes
});
