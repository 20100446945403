import axios from "axios";

import { Notification } from "element-ui";
import qs from "qs";
// console.log(process.env.VUE_APP_BASE_API);
window.qs = qs;
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
});

// 请求拦截器
service.interceptors.request.use(
  // 在发送请求之前做些什么
  config => {
    // config.url = `${config.url}/${process.env.VUE_APP_BASE_API_VERSION}`
    // if(!config.headers["Content-Type"]){
    //   config.headers["Content-Type"] = "application/x-www-form-urlencoded;charset=utf-8";
    // }
    // if(store.getters.token){
    //   config.headers['Authorization'] = 'basicAuth ' + store.getters.token;
    // }
    // if(config.headers["Content-Type"] === "application/x-www-form-urlencoded;charset=utf-8"){
    //   if (config.method === "put" || config.method === "post" ) {
    //     //序列化data数据
    //     config.transformRequest = [function (data) {
    //       return qs.stringify(data);
    //     }];
    //   }else {
    //     //序列化param数据
    //     config.paramsSerializer = function(param) {
    //       return qs.stringify(param,{ arrayFormat: "indices" })
    //     }
    //   }
    // }
    return config;
  },
  // 对请求出错的操作
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截
service.interceptors.response.use(
  function(response) {
    const data = response.data;
    if (data.errNo != 200) {
      // Notification({
      //   title: "错误",
      //   message: data.msg,
      //   type: "error"
      // });
      // throw data.msg
      // this.$message.error(data.msg);
    }
    return Promise.resolve(data);
  },
  function(error) {
    if (error.response) {
      const statusCode = {
        "401": "当前用户失去登录权限，请重新登录",
        "500": "系统出现错误，请联系管理员"
      };
      this.$message.error(statusCode[error.response.status]);
    } else {
      return Promise.reject(error.message);
    }
  }
);

export default service;
