import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import store from "./store";

// 核心插件配置
import chartCore from "@/plugins/chart_core";
Vue.use(chartCore);

import router from "./router";

new Vue({
  router,
  store,
  render: h => h(App),
  created() {},
  mounted() {}
}).$mount("#app");
