const state = {};
const getters = {};
const mutations = {};
const actions = {
  LOGIN({ commit }, userInfo) {
    return new Promise((resolve, reject) => {});
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
